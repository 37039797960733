




import AnalyticsHelper from '@/modules/helpers/analytics-facebook.helper';
import CookiesConsent from '@/modules/layout/components/cookies-consent/CookiesConsent.vue';
import { FacebookPixelEvent } from '@/plugins/analytics';
import { store } from '@/store/store';
import { reaction } from 'mobx';
import { bootstrap, optIn, optOut, setOptions } from 'vue-gtag';
import { Component, Vue } from 'vue-property-decorator';
import { StringUtils } from '../utils/string-utils';
import AnalyticsGoogleHelper from '@/modules/helpers/analytics-google.helper';

@Component({ components: { CookiesConsent } })
export default class AppInitializer extends Vue {
  private boostrappingInProgress = false;
  private pixelBootstrapped = false;

  private get googleAnalyticsId(): string | undefined {
    return this.$config.GoogleAnalyticsId;
  }

  private get facebookPixelId(): string | undefined {
    return this.$config.FacebookPixelId;
  }

  get isStoreLoaded() {
    return store.isStoreLoaded();
  }

  public async mounted(): Promise<void> {
    this.setupGoogleAnalytics();
    this.setupFacebookPixel();

    reaction(
      () => store.googleAnalyticsEnabled,
      () => this.setupGoogleAnalytics()
    );

    reaction(
      () => store.facebookPixelEnabled,
      () => this.setupFacebookPixel()
    );
  }

  private setupGoogleAnalytics(): void {
    if (this.boostrappingInProgress) return;

    const canEnable = this.canEnableGoogleAnalytics();
    if (canEnable && !AnalyticsGoogleHelper.analyticsBootstrapped) {
      this.boostrapGoogleAnalytics();
    } else if (AnalyticsGoogleHelper.analyticsBootstrapped) {
      if (canEnable) optIn();
      else optOut();
    }
  }

  private boostrapGoogleAnalytics(): void {
    this.boostrappingInProgress = true;

    setOptions({
      config: {
        id: this.googleAnalyticsId as string,
        params: {
          // The google analytics script is automatically sending page_view events, but for SPAs (Single Page Applications)
          // page_view events should be sent manually - https://developers.google.com/analytics/devguides/collection/gtagjs/pages#manual_pageviews
          // Setting send_page_view to false will not trigger the first hit on the page.
          // The vue-gtag library is using the router to send the page_view events.
          // eslint-disable-next-line @typescript-eslint/camelcase
          send_page_view: false
          // debug_mode: true
        }
      }
    });

    bootstrap()
      .then(() => {
        AnalyticsGoogleHelper.analyticsBootstrapped = true;
        this.boostrappingInProgress = false;
      })
      .catch(() => {
        AnalyticsGoogleHelper.analyticsBootstrapped = false;
        this.boostrappingInProgress = false;
      });
  }

  private canEnableGoogleAnalytics(): boolean {
    return StringUtils.isNullOrWhiteSpace(this.googleAnalyticsId) === false && store.googleAnalyticsEnabled === true;
  }

  private setupFacebookPixel(): void {
    if (this.pixelBootstrapped) return;

    if (this.canEnableFacebookPixel()) {
      AnalyticsHelper.initFacebookPixel(this.facebookPixelId as string);
      AnalyticsHelper.trackWithFacebookPixel(FacebookPixelEvent.pageView);
      this.pixelBootstrapped = true;
    }
  }

  private canEnableFacebookPixel(): boolean {
    return StringUtils.isNullOrWhiteSpace(this.facebookPixelId) === false && store.facebookPixelEnabled === true;
  }
}
