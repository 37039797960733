



















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class FormSelect extends Vue {
  @Prop() value!: string;
  @Prop() label!: string;
  @Prop() items!: string[];
  @Prop() itemText!: string;
  @Prop() itemValue!: string;

  updateValue(value: string) {
    this.$emit('input', value);
  }
}
