import { Vue } from 'vue-property-decorator';

export default class NotificationService {
  public static info(message: string): void {
    Vue.notify({
      group: 'main',
      type: 'info',
      text: message,
      duration: 6000
    });
  }
  public static error(message: string): void {
    Vue.notify({
      group: 'main',
      type: 'error',
      text: message,
      duration: 6000
    });
  }
  public static warning(message: string): void {
    Vue.notify({
      group: 'main',
      type: 'warning',
      text: message,
      duration: 6000
    });
  }
  public static success(message: string): void {
    Vue.notify({
      group: 'main',
      type: 'success',
      text: message,
      duration: 6000
    });
  }
}
