export type ConsentBannerViewState = 'hidden' | 'generalConsent' | 'privacySettings';

export class ConsentViewModel {
  public accepted = true;

  constructor(
    /**
     * consentName is also the key from translations
     */
    public consentName: string
  ) {}
}

export class ConsentGroupViewModel {
  public expanded = false;

  public get accepted(): boolean {
    return this.acceptedState;
  }

  public set accepted(value: boolean) {
    this.acceptedState = value;
    this.elements.forEach(i => (i.accepted = value));
  }

  private acceptedState = true;

  constructor(
    /**
     * groupName is also the key from translations
     */
    public groupName: string,
    public readonly readonly: boolean,
    public elements: ConsentViewModel[]
  ) {}
}
