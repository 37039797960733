import { app } from '@/main';
import { AnalyticsEvent } from '@/plugins/analytics';

export default class AnalyticsGoogleHelper {
  public static analyticsBootstrapped = false;

  public static event(eventType: AnalyticsEvent, data?: any): void {
    if (this.analyticsBootstrapped) {
      app.$gtag.event(eventType, data);
    }
  }
}
