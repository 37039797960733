













































































































































































import { Component, Vue } from 'vue-property-decorator';
import { Locales } from '@/plugins/i18n';
import { Route } from '@/router/index.enum';

@Component
export default class Post5 extends Vue {
  locales = Locales;
  public route = Route;
}
