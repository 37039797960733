import Vue from 'vue';
import FormTextInput from '@/modules/layout/components/forms/FormTextInput.vue';
import FormTextInfo from '@/modules/layout/components/forms/FormTextInfo.vue';
import FormAutocompleteSelect from '@/modules/layout/components/forms/FormAutocompleteSelect.vue';
import FormSelect from '@/modules/layout/components/forms/FormSelect.vue';
import FormConditionsCheckbox from '@/modules/layout/components/forms/FormConditionsCheckbox.vue';
import SkeletonLoaderDetails from '@/modules/layout/components/SkeletonLoaderDetails.vue';
import VideoContainer from '@/modules/layout/components/VideoContainer.vue';

Vue.component('form-text-input', FormTextInput);
Vue.component('form-text-info', FormTextInfo);
Vue.component('form-autocomplete-select', FormAutocompleteSelect);
Vue.component('form-select', FormSelect);
Vue.component('form-conditions-checkbox', FormConditionsCheckbox);
Vue.component('skeleton-loader-details', SkeletonLoaderDetails);
Vue.component('video-container', VideoContainer);
