






















































import { Component, Prop, Vue } from 'vue-property-decorator';
import BlogPostModel from '../models/blog-post.model';
import { Route } from '@/router/index.enum';

@Component
export default class BlogPostPreview extends Vue {
  @Prop() post!: BlogPostModel;

  public route = Route;
}
