import BlogPostModel from './blog-post.model';
import Post1 from '../posts/Post1.vue';
import Post2 from '../posts/Post2.vue';
import Post3 from '../posts/Post3.vue';
import Post4 from '../posts/Post4.vue';
import Post5 from '../posts/Post5.vue';
import Post6 from '../posts/Post6.vue';
import Post7 from '../posts/Post7.vue';
import Post8 from '../posts/Post8.vue';
import Post9 from '../posts/Post9.vue';
import Post10 from '../posts/Post10.vue';
import Post11 from '../posts/Post11.vue';

export const blogPosts: BlogPostModel[] = [
  {
    link: 'ingamehr-101-video-2',
    title: 'Game overview',
    subTitle: '',
    user: 'Marcin from CODEFUSION',
    image: require('@/assets/images/blog/post7/101_v2.png'),
    date: '2021-08-06',
    shortDescription: 'In this video we present our game collection.',
    content: Post7
  },
  {
    link: 'ingamehr-it-works',
    title: 'InGame HR - it works!',
    subTitle: '',
    user: 'Marcin from CODEFUSION',
    image: require('@/assets/images/blog/post6/create_game_instance.png'),
    date: '2021-07-14',
    shortDescription: 'We created InGame HR for our own company as well. We use it regularly on various occasions.',
    content: Post6
  },
  {
    link: 'ingamehr-101-video-1',
    title: 'The first use, the first game - video 1',
    subTitle: '',
    user: 'Marcin from CODEFUSION',
    image: require('@/assets/images/blog/101_v1.png'),
    date: '2021-05-31',
    shortDescription:
      'This is the first from the InGame 101 tutorial video series. Create your first game recruitment..',
    content: Post5
  },
  {
    link: 'ingamehr-space-rescue-video-game',
    title: 'The game in InGame HR: Space rescue',
    subTitle: 'Branding the game inside InGame HR',
    user: 'Marcin from CODEFUSION',
    image: require('@/assets/images/blog/start-page.png'),
    date: '2021-02-05',
    shortDescription:
      'The core concept of InGame HR is to provide a fun and entertaining video game for the potential candidate in order to increase his involvement in the recruitment process. The quality of the game is the crucial part.',
    content: Post4
  },
  {
    link: 'dogfooding-ingame-hr-2',
    title: 'Dogfooding InGame HR for the second time',
    subTitle: "We've checked InGame HR on our own online event",
    user: 'Marcin from CODEFUSION',
    image: require('@/assets/images/blog/contest_polsl.png'),
    date: '2021-01-15',
    shortDescription:
      'Our company CODEFUSION has a good brand as a good place to work in Opole, Poland. We are a small company and Opole is a small city (about 130 k residents).',
    content: Post3
  },
  {
    link: 'online-recruitement-in-the-time-of-pandemic',
    title: 'Online recruitment in the time of pandemic',
    subTitle: 'The birth if InGame HR',
    user: 'Marcin from CODEFUSION',
    image: require('@/assets/images/blog/jobfair2019.png'),
    date: '2020-11-26',
    shortDescription:
      'Recruiting and hiring became tough in the time of pandemic. Social distancing, self-isolation and home office makes recruiting as we know it impossible. We, at CODEFUSION, had to reinvent some of the processes.',
    content: Post2
  },
  {
    link: 'dogfooding-ingame-hr',
    title: 'Dogfooding InGame HR',
    subTitle: 'Online recruitment in the time of pandemic',
    user: 'Marcin from CODEFUSION',
    image: require('@/assets/images/blog/poc1.png'),
    date: '2020-11-12',
    shortDescription:
      'The 12th Job Fair of the Opole University of Technology was held on November 17, 2020. Due to the fact that the fair was fully virtual and we did not want to give up the survey for students, we created InGame HR - an online survey.',
    content: Post1
  }
];

export const blogPostsPL: BlogPostModel[] = [
  {
    link: 'checkout-the-competition',
    title: 'Tworzenie instancji gry - tutorial',
    subTitle: '',
    user: 'Marcin z CODEFUSION',
    image: require('@/assets/images/blog/post11/post-11.png'),
    date: '2021-10-05',
    shortDescription: 'W tym filmie dowiesz się jak stworzyć instancję gry i użyć jej w rekrutacji online.',
    content: Post11
  },
  {
    link: 'checkout-the-competition',
    title: 'Sprawdzamy konkurencję',
    subTitle: '',
    user: 'Marcin z CODEFUSION',
    image: require('@/assets/images/blog/post10/post-10.png'),
    date: '2021-09-13',
    shortDescription:
      'InGame HR to system stworzony do wspomagania rekrutacji poprzez gry komputerowe. Ten element systemu wyróżnia go na tle konkurencji. Przyjrzyjmy się jej jednak i porównajmy naszą platformę rekrutacyjną z innymi narzędziami na rynku.',
    content: Post10
  },
  {
    link: 'end-of-boring-recruitment-video',
    title: 'Koniec nudnej rekrutacji - video',
    subTitle: '',
    user: 'Marcin z CODEFUSION',
    image: require('@/assets/images/blog/post9/post-9.jpg'),
    date: '2021-09-06',
    shortDescription: 'W tym video przedstawiamy genezę powstania InGame HR.',
    content: Post9
  },
  {
    link: 'gamification',
    title: 'Grywalizacja w rekrutacji',
    subTitle: '',
    user: 'Marcin z CODEFUSION',
    image: require('@/assets/images/blog/post8/post-8.png'),
    date: '2021-08-26',
    shortDescription:
      'Grywalizacja zwana też gamifikacją (od ang. gamification) to używanie elementów gier w dziedzinach do tej pory z grami nie kojarzonymi. ',
    content: Post8
  },
  {
    link: 'ingamehr-101-video-2',
    title: 'Przegląd gier - tutorial 2',
    subTitle: '',
    user: 'Marcin z CODEFUSION',
    image: require('@/assets/images/blog/post7/101_v2.png'),
    date: '2021-08-06',
    shortDescription: 'W tym video przedstawiamy aktualny zbiór gier.',
    content: Post7
  },
  {
    link: 'ingamehr-it-works',
    title: 'InGame HR - to działa!',
    subTitle: '',
    user: 'Marcin z CODEFUSION',
    image: require('@/assets/images/blog/post6/create_game_instance.png'),
    date: '2021-07-14',
    shortDescription: 'InGame HR stworzyliśmy również dla siebie. Używamy go regularnie przy różnych okazjach.',
    content: Post6
  },
  {
    link: 'ingamehr-101-video-1',
    title: 'Pierwsze użycie, pierwsza gra - video 1',
    subTitle: '',
    user: 'Marcin z CODEFUSION',
    image: require('@/assets/images/blog/101_v1.png'),
    date: '2021-05-31',
    shortDescription: 'Stwórz swoją pierwszą grę do rekrutacji. To naprawdę proste!',
    content: Post5
  },
  {
    link: 'ingamehr-space-rescue-video-game',
    title: 'Gra w InGame HR: Ratowanie kosmosu',
    subTitle: 'Tworzenie marki gry w InGame HR',
    user: 'Marcin z CODEFUSION',
    image: require('@/assets/images/blog/start-page.png'),
    date: '2021-02-05',
    shortDescription:
      'Podstawowym założeniem InGame HR jest dostarczenie potencjalnemu kandydatowi zabawnej i rozrywkowej gry wideo, która zwiększy jego zaangażowanie w proces rekrutacji. Kluczowym elementem jest jakość gry.',
    content: Post4
  },
  {
    link: 'dogfooding-ingame-hr-2',
    title: 'Dogfooding InGame HR po raz drugi',
    subTitle: 'Sprawdziliśmy InGame HR na naszym własnym wydarzeniu online',
    user: 'Marcin z CODEFUSION',
    image: require('@/assets/images/blog/contest_polsl.png'),
    date: '2021-01-15',
    shortDescription:
      'Nasza firma CODEFUSION ma dobrą markę i doskonałe miejsce do pracy w Opolu. Ale potencjalny pracownik musi się o tym dowiedzieć. ',
    content: Post3
  },
  {
    link: 'online-recruitement-in-the-time-of-pandemic',
    title: 'Rekrutacja online w czasie pandemii',
    subTitle: 'Narodziny InGame HR',
    user: 'Marcin z CODEFUSION',
    image: require('@/assets/images/blog/jobfair2019.png'),
    date: '2020-11-26',
    shortDescription:
      'Rekrutacja i zatrudnianie stały się trudne w czasach pandemii. Dystans społeczny, izolacja i home office sprawiają, że rekrutacja, jaką znamy, staje się niemożliwa. My, w CODEFUSION, musieliśmy wymyślić niektóre z procesów na nowo.',
    content: Post2
  },
  {
    link: 'dogfooding-ingame-hr',
    title: 'Dogfooding InGame HR',
    subTitle: 'Rekrutacja online w czasie pandemii',
    user: 'Marcin z CODEFUSION',
    image: require('@/assets/images/blog/poc1.png'),
    date: '2020-11-12',
    shortDescription:
      'W dniu 17 listopada 2020 roku odbyły się XII Targi Pracy Politechniki Opolskiej. Ze względu na to, że targi były w pełni wirtualne, a my nie chcieliśmy rezygnować z ankiety dla studentów, stworzyliśmy InGame HR - ankietę online.',
    content: Post1
  }
];
