import { translate } from '@/plugins/i18n';

export default class StringValidators {
  public static required(value: string): boolean | string {
    return !!value || translate('Validation.Required');
  }

  public static minLength(value: string, length: number): boolean | string {
    return value?.length >= length || translate('Validation.MinLength', { minLength: length });
  }

  public static maxLength(value: string, length: number): boolean | string {
    return value?.length <= length || translate('Validation.MaxLength', { maxLength: length });
  }

  public static email(value: string): boolean | string {
    return (
      (!!value && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value)) || translate('Validation.Email')
    );
  }
  public static minNumber(value: number, min: number): boolean | string {
    return value >= min || translate('Validation.MinNumber', { min });
  }
  public static maxNumber(value: number, max: number): boolean | string {
    return value <= max || translate('Validation.MaxNumber', { max });
  }
}
