var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('aside',{staticClass:"sidebar"},[_c('div',{staticClass:"widget widget-latest-post"},[_c('h4',{staticClass:"widget-title"},[_vm._v(_vm._s(_vm.$t('Views.BlogPost.LatestPosts')))]),_vm._l((_vm.posts),function(post){return _c('div',{key:post.link,staticClass:"media"},[_c('router-link',{attrs:{"to":{
          name: _vm.route.blogPost,
          params: {
            postId: post.link
          }
        }}},[_c('img',{staticClass:"media-object",attrs:{"src":post.image,"alt":post.title}})]),_c('div',{staticClass:"media-body"},[_c('h4',{staticClass:"media-heading"},[_c('router-link',{attrs:{"link":"","to":{
              name: _vm.route.blogPost,
              params: {
                postId: post.link
              }
            }}},[_vm._v(_vm._s(post.title))])],1),_c('p',{staticClass:"text-truncate"},[_vm._v(_vm._s(post.shortDescription))])])],1)})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }