import { render, staticRenderFns } from "./BlogPostList.vue?vue&type=template&id=d8c0440e&scoped=true&"
import script from "./BlogPostList.vue?vue&type=script&lang=ts&"
export * from "./BlogPostList.vue?vue&type=script&lang=ts&"
import style0 from "./BlogPostList.vue?vue&type=style&index=0&id=d8c0440e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d8c0440e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VContainer,VRow})
