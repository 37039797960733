









































































































































































































































































































































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import PageTitle from '@/modules/layout/components/PageTitle.vue';
import { Locales } from '@/plugins/i18n';

@Component({ components: { PageTitle } })
export default class Terms extends Vue {
  locales = Locales;
}
