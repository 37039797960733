import Vue from 'vue';
import Home from '@/modules/page/views/Home.vue';
import About from '@/modules/page/views/About.vue';
import Contact from '@/modules/page/views/Contact.vue';
import Ebook from '@/modules/page/views/Ebook.vue';
import BlogPostList from '@/modules/page/views/BlogPostList.vue';
import BlogPost from '@/modules/page/views/BlogPost.vue';
import Privacy from '@/modules/page/views/Privacy.vue';
import Terms from '@/modules/page/views/Terms.vue';
import VueRouter, { RouteConfig } from 'vue-router';
import { warnAboutProxyRequirement } from 'mobx/dist/internal';
import { Route } from '@/router/index.enum';
Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '',
    name: Route.home,
    component: Home,
    props: true
  },
  {
    path: '/blog',
    name: Route.blogPostList,
    component: BlogPostList,
    props: true
  },
  {
    path: '/blog/:postId',
    name: Route.blogPost,
    component: BlogPost,
    props: true
  },
  {
    path: '/about',
    name: Route.about,
    component: About,
    props: true
  },
  {
    path: '/contact',
    name: Route.contact,
    component: Contact,
    props: true
  },
  {
    path: '/ebook/7_sposobow_na_skuteczna_rekrutacje_okiem_rekrutera',
    name: Route.ebook,
    component: Ebook,
    props: true
  },
  {
    path: '/privacypolicy',
    name: Route.privacy,
    component: Privacy,
    props: true
  },
  {
    path: '/termsandconditions',
    name: Route.terms,
    component: Terms,
    props: true
  },
  {
    path: '*',
    redirect: {
      name: Route.home,
      replace: true
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return {
      selector: '#scroll-anchor',
      behavior: 'smooth'
    };
  }
});

export default router;
