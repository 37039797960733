import { translate } from '@/plugins/i18n';
import {LandingPageClient, SubmitContactFormInputModel, SubmitEmailFormInputModel, SwaggerResponse} from './Api';
import NotificationService from './notification-service';

export default class ApiService {
  constructor(public url: string) {}
  private client = new LandingPageClient(this.url);

  public async submitContactForm(model: SubmitContactFormInputModel): Promise<void> {
    try {
      const result = await this.client.submitContactForm(model);
      return result.result;
    } catch (error) {
      this.HandleError(error);
    }
  }

  public async submitEbookForm(model: SubmitEmailFormInputModel): Promise<void> {
    try {
      const result = await this.client.submitEbookForm(model);
      return result.result;
    } catch (error) {
      this.HandleError(error);
    }
  }

  protected HandleError(error: SwaggerResponse<string>) {
    if (error?.status == 404) {
      NotificationService.error(translate('Errors.CannotConnect'));
      return;
    }

    if ((error.result as any).title) {
      NotificationService.error((error.result as any).title);
      return;
    }

    NotificationService.error(translate(error.result));
  }
}
