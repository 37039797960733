








































































import { Locales } from '@/plugins/i18n';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Post10 extends Vue {
  locales = Locales;
}
