



































































































































































































































































































































import PageTitle from '@/modules/layout/components/PageTitle.vue';
import { Component, Vue } from 'vue-property-decorator';
import HomeHeaderSection from '@/modules/page/components/HomeHeaderSection.vue';
import { store } from '@/store/store';
import StringValidators from '@/modules/layout/validators/string-validators';
import ApiSerivce from '@/services/api-service';
import { SubmitEmailFormInputModel } from '@/services/Api';
import { AnalyticsEvent, FacebookPixelEvent } from '@/plugins/analytics';
import AnalyticsHelper from '@/modules/helpers/analytics-facebook.helper';
import { Locales } from '@/plugins/i18n';
import { reaction } from 'mobx';
import AnalyticsGoogleHelper from '@/modules/helpers/analytics-google.helper';
import { Route } from '@/router/index.enum';

type VForm = Vue & { validate: () => boolean };

@Component({ components: { HomeHeaderSection, PageTitle } })
export default class Home extends Vue {
  userEmail = '';
  termsCheckbox = false;
  ebook = false;
  public route = Route;
  routeName = this.$route.name;

  emailSent = false;

  apiService!: ApiSerivce;

  rules = {
    required: StringValidators.required,
    minLength: (model: string) => StringValidators.minLength(model, 3),
    maxLength: (model: string) => StringValidators.maxLength(model, 300),
    email: StringValidators.email
  };

  //// Code below is commented temporary. It is responsible for ebook popup.

  mounted() {
    // reaction(
    //     () => store.language,
    //     () => this.tryToShowEbookPopup()
    // );
    this.apiService = new ApiSerivce(this.$config.SystemUrl);
    this.emailSent = false;
    // this.tryToShowEbookPopup();
  }

  // tryToShowEbookPopup(){
  //   setTimeout(() => {
  //     if (store.isEbookFormShown || !this.$config.EbookPopupEnabled || this.$i18n.locale != Locales.PL || this.$route.name != this.routeName) {
  //       return;
  //     }
  //     this.ebook = true;
  //     store.setEbookFormState(true);
  //   }, this.$config.EbookPopupDelay);
  // }

  get registerUrl(): string {
    const redirectUri = this.$config.SystemUrl + '/register';
    return redirectUri;
  }

  async submit() {
    const form = this.$refs.form as VForm;
    if (!form.validate()) {
      return;
    }
    const inputModel = new SubmitEmailFormInputModel({
      email: this.userEmail
    });
    this.emailSent = true;
    setTimeout(() => {
      this.ebook = false;
    }, 2000);
    this.apiService.submitEbookForm(inputModel);
    AnalyticsGoogleHelper.event(AnalyticsEvent.ebookFormUsed);
    AnalyticsHelper.trackWithFacebookPixel(FacebookPixelEvent.ebook);
  }
}
