import { Locales } from '@/plugins/i18n';
import CookieService from '@/services/cookie-service';
import { store } from '@/store/store';
import { getUserLocale } from 'get-user-locale';

export default class LocaleHelper {
  public static getLocale(): string | undefined {
    const storedLanguage = store.getLanguageCode;
    if (storedLanguage) {
      return storedLanguage;
    }
    const cookieLanguage = CookieService.getCookie();
    if (cookieLanguage && cookieLanguage.languageCode) {
      return cookieLanguage.languageCode;
    }
    const browserLanguage = getUserLocale();
    if (browserLanguage?.length >= 2) {
      const localeKey = browserLanguage.substr(0, 2);
      if (Object.values(Locales).includes(localeKey as Locales)) {
        return localeKey;
      }
      console.log('browserLanguage not supported', browserLanguage);
    }

    console.log('Default: ', Locales.EN);
    return Locales.EN;
  }
}
