


























import { Component, Vue } from 'vue-property-decorator';
import PageTitle from '@/modules/layout/components/PageTitle.vue';

@Component({ components: { PageTitle } })
export default class About extends Vue {}
