

















































import { Component, Vue } from 'vue-property-decorator';

@Component
export default class HomeHeaderSection extends Vue {
  private showVideo = false;

  get registerUrl(): string {
    const redirectUri = this.$config.SystemUrl + '/register';
    return redirectUri;
  }
}
