




















import { Component, Prop, Vue } from 'vue-property-decorator';
import { Route } from '@/router/index.enum';

@Component
export default class FormConditionsCheckbox extends Vue {
  @Prop({ type: Boolean, default: false }) value!: boolean;
  @Prop({ type: Boolean, default: false }) newTab!: boolean;
  @Prop({ type: Boolean, default: false }) disabled!: boolean;
  @Prop({ type: String, default: Route.terms }) link!: Route;
  @Prop({ type: String, default: 'Privacy Policy' }) linkText!: string;
  @Prop({ type: String, default: 'I agree to the' }) label!: string;
  @Prop({ type: String, default: '' }) labelAfterLink!: string;

  router = this.$router;

  public get routerLink() {
    const routerdata = this.router.resolve({ name: this.link });

    return routerdata.href;
  }

  public get newTabFlag() {
    return this.newTab ? '_blank' : '_self';
  }

  updateValue(value: boolean) {
    this.$emit('input', value);
  }
}
