import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import DatetimePicker from 'vuetify-datetime-picker';
// (Optional) import 'vuetify-datetime-picker/src/stylus/main.styl'

Vue.use(Vuetify);
Vue.use(DatetimePicker);

const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#30ccdd',
        secondary: '#e85c39',
        background: '#e0e0e0',
        blue: '#1AABBC'
      }
    }
  }
});

export default vuetify;
