import { render, staticRenderFns } from "./NotificationHolder.vue?vue&type=template&id=8ac3d556&scoped=true&"
import script from "./NotificationHolder.vue?vue&type=script&lang=ts&"
export * from "./NotificationHolder.vue?vue&type=script&lang=ts&"
import style1 from "./NotificationHolder.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8ac3d556",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
installComponents(component, {VAlert})
