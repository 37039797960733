import { ConsentBannerViewState } from '@/modules/layout/components/cookies-consent/cookies-consent.model';
import { i18n } from '@/plugins/i18n';
import vuetify from '@/plugins/vuetify';
import CookieService from '@/services/cookie-service';
import { action, autorun, computed, extendObservable, observable } from 'mobx';

export default class Store {
  private storeVersion = 'v1';
  private storeLoaded = false;

  constructor() {
    autorun(async () => {
      if (!this.storeLoaded) {
        const existingStore = localStorage.getItem('store');

        if (existingStore) {
          const parsedStore = JSON.parse(existingStore) as Store;
          this.loadCookieData(parsedStore);
          parsedStore.storeLoaded = false;

          if (parsedStore.storeVersion !== this.storeVersion) {
            console.info('invalid store version, resetting consent');
            parsedStore.consentBannerViewState = undefined;
            parsedStore.storeVersion = this.storeVersion;
          }

          extendObservable(this, parsedStore);
        } else {
          this.loadCookieData(this);
        }

        if (!this.consentBannerViewState) {
          this.consentBannerViewState = 'generalConsent';
        }

        this.storeLoaded = true;
      }

      this.saveState();
    });
  }

  loadCookieData(storeModel: Store) {
    const cookie = CookieService.getCookie();
    if (cookie) {
      storeModel.language = cookie.languageCode;
      // always override these values on init
      storeModel.isInternalTraffic = cookie?.internalTraffic === true ? true : undefined;
      storeModel.googleAnalyticsAccepted = cookie?.googleAnalyticsAccepted === true;
      storeModel.facebookPixelAccepted = cookie?.facebookPixelAccepted === true;
      storeModel.ebookFormShown = cookie?.wasEbookFormShown === true;
      storeModel.consentBannerViewState = cookie?.wasCookieBannerShown === true ? 'hidden' : 'generalConsent';
    }
  }

  saveState() {
    const serialized = JSON.stringify(this);
    localStorage.setItem('store', serialized);
  }

  @observable appVersion?: string = undefined;
  @observable language?: string = undefined;
  @observable isInternalTraffic?: boolean = undefined;
  @observable googleAnalyticsAccepted?: boolean = undefined;
  @observable facebookPixelAccepted?: boolean = undefined;
  @observable ebookFormShown?: boolean = undefined;
  @observable consentBannerViewState?: ConsentBannerViewState = undefined;

  @computed get googleAnalyticsEnabled(): boolean {
    if (this.isInternalTraffic === true) return false;
    return this.googleAnalyticsAccepted === true;
  }

  @computed get facebookPixelEnabled(): boolean {
    if (this.isInternalTraffic === true) return false;
    return this.facebookPixelAccepted === true;
  }

  @computed get consentBannerState() {
    return this.consentBannerViewState;
  }

  @computed get isEbookFormShown() {
    return this.ebookFormShown;
  }

  @computed get getLanguageCode() {
    return this.language;
  }

  @computed isStoreLoaded(): boolean {
    return this.storeLoaded;
  }

  @action setLanguage(code: string) {
    this.language = code;
    this.saveState();
    i18n.locale = code;
    vuetify.framework.lang.current = code;
    CookieService.setCookieProperty('languageCode', code);
  }

  @action setConsentBannerState(state: ConsentBannerViewState) {
    this.consentBannerViewState = state;
    this.saveState();
  }

  @action setEbookFormState(shown: boolean) {
    this.ebookFormShown = shown;
    this.saveState();
    CookieService.setCookieProperty('wasEbookFormShown', shown);
  }

  @action setGoogleAnalyticsAcceptedFlag(accepted: boolean): void {
    this.googleAnalyticsAccepted = accepted;
    this.saveState();
    CookieService.setCookieProperty('googleAnalyticsAccepted', accepted);
  }

  @action setFacebookPixelAcceptedFlag(accepted: boolean): void {
    this.facebookPixelAccepted = accepted;
    this.saveState();
    CookieService.setCookieProperty('facebookPixelAccepted', accepted);
  }

  @action setInternalTrafficFlag(): void {
    this.isInternalTraffic = true;
    this.saveState();
    CookieService.setCookieProperty('internalTraffic', true);
  }
}
export const store = new Store();
