import moment from 'moment';
import { Moment } from 'moment';
import { VueConstructor } from 'vue/types/umd';

export default {
  install(Vue: VueConstructor) {
    Vue.filter('formatDateTime', function someFormatFunc1(value: Moment) {
      if (!value || !moment(value).isValid()) {
        return '-';
      }
      const parsed = moment(value).local();
      return parsed.format('YYYY-MM-DD HH:mm');
    });
    Vue.filter('formatDate', function someFormatFunc1(value: Moment) {
      if (!value || !moment(value).isValid()) {
        return '-';
      }

      const parsed = moment(value).local();
      return parsed.format('YYYY-MM-DD');
    });
    Vue.filter('formatHours', function someFormatFunc1(value: Moment) {
      if (!value || !moment(value).isValid()) {
        return '-';
      }

      const parsed = moment(value).local();
      return parsed.format('HH:mm');
    });
  }
};
