










import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class FormTextInfo extends Vue {
  @Prop({ default: null }) currency!: string;
  @Prop() label!: string;
  @Prop() value!: any;
}
