




































import { Component, Vue } from 'vue-property-decorator';
import BlogPostModel from '../models/blog-post.model';
import { blogPosts, blogPostsPL } from '../models/blog-posts';
import { Locales } from '@/plugins/i18n';
import { Route } from '@/router/index.enum';

@Component
export default class BlogPostLatest extends Vue {
  public route = Route;
  get posts(): BlogPostModel[] {
    return this.$i18n.locale === Locales.PL ? blogPostsPL : blogPosts;
  }
}
