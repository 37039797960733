export enum Route {
  home = 'ProductPage Home',
  blogPostList = 'ProductPage Blog Post List',
  blogPost = 'ProductPage Blog Post',
  about = 'ProductPage About',
  contact = 'ProductPage Contact',
  ebook = 'ProductPage Ebook',
  privacy = 'ProductPage Privacy',
  terms = 'ProductPage Terms'
}
