
































import { Locales } from '@/plugins/i18n';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { store } from '@/store/store';
import 'flag-icon-css/css/flag-icon.css';

@Component
export default class ToolBarLanguageSelector extends Vue {
  @Prop({ default: 'transparent' }) color?: string;
  langs = [
    { code: Locales.EN, title: 'English', customFlagCode: 'gb' },
    { code: Locales.DE, title: 'Deutsch' },
    { code: Locales.PL, title: 'Polski' },
    { code: Locales.ES, title: 'Español' },
    { code: Locales.PT, title: 'Português' },
    { code: Locales.FR, title: 'Français' },
    { code: Locales.IT, title: 'Italiano' },
    { code: Locales.RU, title: 'русский' },
    { code: Locales.NL, title: 'Nederlands' }
  ];
  changeLanguage(lang: string) {
    store.setLanguage(lang);
  }
  getLanguage(code: string) {
    const language = this.langs.find(l => l.code === code);
    return language;
  }
  getFlagClass(lang: any) {
    if (lang.customFlagCode) {
      return 'flag-icon-' + lang.customFlagCode;
    }
    return 'flag-icon-' + lang.code;
  }
}
