





















import { Component, Vue } from 'vue-property-decorator';
import { Locales } from '@/plugins/i18n';

@Component
export default class Post11 extends Vue {
  locales = Locales;
}
