import { store } from '@/store/store';
import { Vue } from 'vue-property-decorator';
import { ConfigModel } from './models/config.model';

export default class StartupHelper {
  public static async setupConfig(): Promise<void> {
    const response = await fetch(process.env.BASE_URL + 'config.json', { cache: 'no-store' });
    if (!response.ok) {
      console.error('Invalid config');
    }

    const model = (await response.json()) as ConfigModel;
    model.SystemUrl = model.SystemUrl?.replace(/\/$/, '') ?? '';
    Vue.prototype.$config = model;
    store.appVersion = model.Version;
  }
}
