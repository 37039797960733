













































































import { Component, Vue } from 'vue-property-decorator';
import ToolBarLanguageSelector from '@/modules/layout/components/ToolBarLanguageSelector.vue';
import { Route } from '@/router/index.enum';

@Component({ components: { ToolBarLanguageSelector } })
export default class Header extends Vue {
  links = [
    {
      text: 'Views.Header.Blog',
      route: Route.blogPostList
    },
    {
      text: 'Views.Header.About',
      route: Route.about
    },
    {
      text: 'Views.Header.Contact',
      route: Route.contact
    }
    // {
    //   text: 'Views.Ebook.NavigationTitle',
    //   route: Route.ebook
    // }
  ];
  showNavbar = true;
  isMobile = false;
  colorNav = 'white';
  public route = Route;

  navigate(routeName: string) {
    this.$router.push({ name: routeName });
  }

  mounted() {
    window.onresize = () => {
      this.windowResize(window.innerWidth);
    };
    this.windowResize(window.innerWidth);
  }
  windowResize(e: any) {
    if (e < '1264') {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }
}
