










































































import { Component, Vue } from 'vue-property-decorator';
import PageTitle from '@/modules/layout/components/PageTitle.vue';
import ApiSerivce from '@/services/api-service';
import { SubmitContactFormInputModel } from '@/services/Api';
import StringValidators from '@/modules/layout/validators/string-validators';
import { AnalyticsEvent, FacebookPixelEvent } from '@/plugins/analytics';
import AnalyticsHelper from '@/modules/helpers/analytics-facebook.helper';
import AnalyticsGoogleHelper from '@/modules/helpers/analytics-google.helper';

type VForm = Vue & { validate: () => boolean };

@Component({ components: { PageTitle } })
export default class Contact extends Vue {
  userEmail = '';
  userMessage = '';
  userName = '';
  userSubject = '';

  emailSent = false;

  apiService!: ApiSerivce;

  rules = {
    required: StringValidators.required,
    minLength: (model: string) => StringValidators.minLength(model, 3),
    maxLength: (model: string) => StringValidators.maxLength(model, 300),
    email: StringValidators.email
  };

  mounted() {
    this.apiService = new ApiSerivce(this.$config.SystemUrl);
    this.emailSent = false;
  }

  async submit() {
    const form = this.$refs.form as VForm;
    if (!form.validate()) {
      return;
    }
    const inputModel = new SubmitContactFormInputModel({
      userEmail: this.userEmail,
      userMessage: this.userMessage,
      userName: this.userName,
      userSubject: this.userSubject
    });
    this.emailSent = true;
    this.apiService.submitContactForm(inputModel);
    AnalyticsGoogleHelper.event(AnalyticsEvent.contactFormUsed);
    AnalyticsHelper.trackWithFacebookPixel(FacebookPixelEvent.contact);
  }
}
