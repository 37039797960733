



























































import { Component, Vue } from 'vue-property-decorator';
import PageTitle from '@/modules/layout/components/PageTitle.vue';
import ApiSerivce from '@/services/api-service';
import { SubmitEmailFormInputModel } from '@/services/Api';
import StringValidators from '@/modules/layout/validators/string-validators';
import { AnalyticsEvent, FacebookPixelEvent } from '@/plugins/analytics';
import AnalyticsHelper from '@/modules/helpers/analytics-facebook.helper';
import AnalyticsGoogleHelper from '@/modules/helpers/analytics-google.helper';
import { store } from '@/store/store';
import { Route } from '@/router/index.enum';

type VForm = Vue & { validate: () => boolean };

@Component({ components: { PageTitle } })
export default class Contact extends Vue {
  public route = Route;

  userEmail = '';
  termsCheckbox = false;
  emailSent = false;

  apiService!: ApiSerivce;

  rules = {
    required: StringValidators.required,
    minLength: (model: string) => StringValidators.minLength(model, 3),
    maxLength: (model: string) => StringValidators.maxLength(model, 300),
    email: StringValidators.email
  };

  mounted() {
    this.apiService = new ApiSerivce(this.$config.SystemUrl);
    this.emailSent = false;
    store.setLanguage('pl');
    this.termsCheckbox = false;
  }

  async submit() {
    const form = this.$refs.form as VForm;
    if (!form.validate()) {
      return;
    }
    const inputModel = new SubmitEmailFormInputModel({
      email: this.userEmail,
      languageCode: store.getLanguageCode
    });
    this.emailSent = true;
    this.apiService.submitEbookForm(inputModel);
    AnalyticsGoogleHelper.event(AnalyticsEvent.ebookFormUsed);
    AnalyticsHelper.trackWithFacebookPixel(FacebookPixelEvent.ebook);
  }
}
