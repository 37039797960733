






























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import PageTitle from '@/modules/layout/components/PageTitle.vue';
import { blogPosts, blogPostsPL } from '../models/blog-posts';
import BlogPostModel from '../models/blog-post.model';
import BlogPostLatest from '@/modules/page/components/BlogPostLatest.vue';
import { Locales } from '@/plugins/i18n';

@Component({ components: { PageTitle, BlogPostLatest } })
export default class BlogPost extends Vue {
  @Prop() postId!: string;
  get post(): BlogPostModel | undefined {
    const post =
      this.$i18n.locale === Locales.PL
        ? blogPostsPL.find(p => p.link === this.postId)
        : blogPosts.find(p => p.link === this.postId);
    return post;
  }
}
