var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"post"},[_c('div',{staticClass:"post-media post-thumb"},[_c('router-link',{attrs:{"to":{
        name: _vm.route.blogPost,
        params: {
          postId: _vm.post.link
        }
      }}},[_c('img',{attrs:{"src":_vm.post.image,"alt":_vm.post.title}})])],1),_c('router-link',{attrs:{"to":{
      name: _vm.route.blogPost,
      params: {
        postId: _vm.post.link
      }
    }}},[_c('h3',{staticClass:"post-title"},[_vm._v(" "+_vm._s(_vm.post.title)+" ")])]),_c('div',{staticClass:"post-meta"},[_c('ul',{staticClass:"pl-0"},[_c('li',[_c('i',{staticClass:"ion-calendar"}),_vm._v(" "+_vm._s(_vm.post.date))]),_c('li',[_c('i',{staticClass:"ion-android-people"}),_vm._v(" "+_vm._s(_vm.$t('Views.BlogPost.PostedBy'))+" "+_vm._s(_vm.post.user)+" ")])])]),_c('div',{staticClass:"post-content"},[_c('p',[_vm._v(" "+_vm._s(_vm.post.shortDescription)+" ")]),_c('router-link',{attrs:{"to":{
        name: _vm.route.blogPost,
        params: {
          postId: _vm.post.link
        }
      }}},[_c('div',{staticClass:"btn btn-main"},[_vm._v(_vm._s(_vm.$t('Views.BlogPost.ContinueReading')))])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }