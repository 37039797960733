import { init, track } from 'fbq';
import { FacebookPixelEvent } from '@/plugins/analytics';
import { store } from '@/store/store';
export default class AnalyticsHelper {
  private static initialized = false;
  public static initFacebookPixel(id: string) {
    init(id);
    this.initialized = true;
  }

  public static trackWithFacebookPixel(eventType: FacebookPixelEvent) {
    if (!this.initialized || !store.facebookPixelEnabled) {
      return;
    }
    track(eventType);
  }
}
