var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$i18n.locale === _vm.locales.PL)?_c('div',[_c('p',[_vm._v(" Nasza firma CODEFUSION ma dobrą markę i doskonałe miejsce do pracy w Opolu. Jesteśmy małą firmą, a Opole jest małym miastem (ok. 130 tys. mieszkańców). W Opolu znajdują się dwie uczelnie wyższe kształcące na kierunkach informatycznych, gdzie przeprowadzamy większość rekrutacji. ")]),_c('p',[_vm._v(" Opisywaliśmy już jak wykorzystaliśmy InGame HR na Targach Pracy na Politechnice Opolskiej w "),_c('router-link',{attrs:{"to":{
        name: _vm.route.blogPost,
        params: {
          postId: 'dogfooding-ingame-hr'
        }
      }}},[_c('span',[_vm._v("tym wpisie na blogu")])]),_vm._v(". Na ten test InGame HR wybraliśmy się do Gliwic. Jest to sąsiednie miasto (ok. 180 tys. mieszkańców) z dużą Politechniką. Nigdy nie byliśmy tam widoczni, jako pracodawca. Postanowiliśmy jednak wykorzystać InGame HR na Targach Pracy i Przedsiębiorczości na Politechnice Śląskiej. Odbyły się one w dniach 14-18 grudnia 2020 roku. ")],1),_c('p',[_vm._v(" Tym razem nie organizowaliśmy żadnych webinarów ani konkursów. Zwykłe wirtualne stanowisko na targach plus link do InGame HR. Targi trwały 5 dni (w przeciwieństwie do pierwszych, które trwały tylko jeden dzień). Ogólnie rzecz biorąc, wyniki były bardzo rozczarowujące: ")]),_vm._m(0),_c('p',[_vm._v(" Dodatkowo udało nam się pozyskać jeszcze jeden kontakt przez czat. Łącznie były to więc dwa kontakty z infrastruktury wirtualnych targów. ")]),_c('p',[_vm._v("Podaliśmy link do gry na naszej stronie \"O nas\". Wyglądało to w ten sposób:")]),_vm._m(1),_vm._m(2)]):_c('div',[_c('p',[_vm._v(" Our company CODEFUSION has a good brand and an excellent place to work in Opole, Poland. We are a small company, and Opole is a small city (about 130k residents). There are two Universities in Opole that teach Computer Science where we do most of our recruitment. We’ve described how we used InGame HR for a Job Fair at the Opole University of Technology in "),_c('router-link',{attrs:{"to":{
        name: _vm.route.blogPost,
        params: {
          postId: 'dogfooding-ingame-hr'
        }
      }}},[_c('span',[_vm._v("this blog post")])]),_vm._v(". For this test of InGame HR, we went outside to Gliwice. It is a neighboring city (with approx. 180k residents) and a large Technical University. We were never prominently visible there as an employer. But we decided to use InGame HR at the Job and Entrepreneurship Fair at the Silesian University of Technology. It took part from 14th to 18th of December 2020. We didn’t organize any webinars or competitions this time. Simple virtual boot at the fair plus a link to InGame HR. The fair lasted for 5 days (in contrast to the first one, where we used InGame HR, which lasted only one day). Overall, the results were very disappointing: ")],1),_vm._m(3),_c('p',[_vm._v(" Additionally, we did get one more contact over the chat. So, there were altogether two contacts from the virtual fair infrastructure. ")]),_c('p',[_vm._v("We provided the link for the game on our “About us” page. Like this:")]),_vm._m(4),_vm._m(5)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',[_c('li',[_vm._v("Wyświetlenia - 374")]),_c('li',[_vm._v("Wyświetlenia listy ofert - 129")]),_c('li',[_vm._v("Wyświetlenia ofert - 184")]),_c('li',[_vm._v("Wysłane formularze kontaktowe - 1")]),_c('li',[_vm._v("Kliknięcia na Facebooku - 1")]),_c('li',[_vm._v("Liczba pobranych katalogów reklamowych - 7")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_c('img',{attrs:{"src":require("@/assets/images/blog/contest_polsl.png"),"alt":"Konkurs InGame HR z nagrodami na wirtualnych targach pracy"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_vm._v(" Mieliśmy czterech gości, którzy zagrali w naszą grę. To niewiele, ale jednak "),_c('strong',[_vm._v("dwa razy więcej")]),_vm._v(" niż z samego wirtualnego stanowiska. ")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',[_c('li',[_vm._v("Views - 374")]),_c('li',[_vm._v("Jobs list displayed - 129")]),_c('li',[_vm._v("Job views - 184")]),_c('li',[_vm._v("Sent contact forms - 1")]),_c('li',[_vm._v("Facebook clicks - 1")]),_c('li',[_vm._v("Number of downloaded directories - 7")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_c('img',{attrs:{"src":require("@/assets/images/blog/contest_polsl.png"),"alt":"InGame HR contest with prizes on a virtual job fair"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_vm._v(" We had 4 visitors who played the game. Not much, but still "),_c('strong',[_vm._v("two times more")]),_vm._v(" than from the virtual boot itself. ")])}]

export { render, staticRenderFns }