/**
 * Events reference: https://developers.google.com/gtagjs/reference/ga4-events
 *
 * Some events are from existing events reference and some events are custom ones.
 */
export enum AnalyticsEvent {
  contactFormUsed = 'contact_form_used',
  ebookFormUsed = 'generate_lead'
}

export enum FacebookPixelEvent {
  pageView = 'PageView',
  contact = 'Contact',
  ebook = 'Lead'
}
