











import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class PageTitle extends Vue {
  @Prop() title?: string;
  @Prop() subTitle?: string;
}
