import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from '@/i18n/en.json';
import pl from '@/i18n/pl.json';
import de from '@/i18n/de.json';
import es from '@/i18n/es.json';
import nl from '@/i18n/nl.json';
import fr from '@/i18n/fr.json';
import it from '@/i18n/it.json';
import pt from '@/i18n/pt.json';
import ru from '@/i18n/ru.json';
import LocaleHelper from '@/i18n/locale-helper';
import { store } from '@/store/store';

export enum Locales {
  EN = 'en',
  DE = 'de',
  PL = 'pl',
  ES = 'es',
  FR = 'fr',
  IT = 'it',
  PT = 'pt',
  RU = 'ru',
  NL = 'nl'
}

export const messages = {
  [Locales.EN]: en,
  [Locales.DE]: de,
  [Locales.PL]: pl,
  [Locales.ES]: es,
  [Locales.FR]: fr,
  [Locales.IT]: it,
  [Locales.PT]: pt,
  [Locales.RU]: ru,
  [Locales.NL]: nl
};

export const defaultLocale = Locales.EN;

Vue.use(VueI18n);
const i18n = new VueI18n({
  messages,
  locale: LocaleHelper.getLocale(),
  fallbackLocale: defaultLocale,

  missing(locale: any, key: any, vm: any) {
    console.warn('Locale is missing: ', locale, key, vm);
    return key;
  }
});

const translate = (key: string, values?: VueI18n.Values): any => (!key ? '' : i18n.t(key, values));

export { i18n, translate };
