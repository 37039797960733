


















import { Component, Vue } from 'vue-property-decorator';
import PageTitle from '@/modules/layout/components/PageTitle.vue';
import BlogPostLatest from '@/modules/page/components/BlogPostLatest.vue';
import BlogPostPreview from '@/modules/page/components/BlogPostPreview.vue';
import BlogPostModel from '../models/blog-post.model';
import { blogPosts, blogPostsPL } from '@/modules/page/models/blog-posts';
import { Locales } from '@/plugins/i18n';

@Component({ components: { PageTitle, BlogPostLatest, BlogPostPreview } })
export default class BlogPostList extends Vue {
  get posts(): BlogPostModel[] {
    return this.$i18n.locale === Locales.PL ? blogPostsPL : blogPosts;
  }
}
