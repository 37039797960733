
































































import { store } from '@/store/store';
import { Component, Vue } from 'vue-property-decorator';
import { Route } from '@/router/index.enum';

@Component
export default class Footer extends Vue {
  public store = store;
  public route = Route;

  public get year(): number {
    return new Date().getFullYear();
  }
}
