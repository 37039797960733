














import AppInitializer from '@/modules/layout/components/AppInitializer.vue';
import Footer from '@/modules/layout/components/Footer.vue';
import Header from '@/modules/layout/components/Header.vue';
import NotificationHolder from '@/modules/layout/components/NotificationHolder.vue';
import Vue from 'vue';

export default Vue.extend({
  name: 'App',
  components: { Header, Footer, NotificationHolder, AppInitializer }
});
